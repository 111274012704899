import { Box, Button, Divider, Typography } from '@mui/material'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { Icon, FeatureContentList } from 'components'
import React from 'react'
import { Content } from 'types'

const BeforeApplication = () => {
  const content = useEpiContent<Content>()
  const { userRoles } = useAuthentication()
  const userHasMissingMobile = userRoles.includes('MissingMobile')
  const btnTestId = userHasMissingMobile ? 'missingMobileLink' : 'ansokanLink'
  const btnUrl = userHasMissingMobile
    ? content?.missingMobileLinkUrl
    : content?.linkUrlBeforeApplication
  return (
    <Box data-testid="BeforeApplication">
      <FeatureContentList listType="contentListBeforeApplication" />
      <Button data-testid={btnTestId} href={btnUrl} sx={{ mb: 6 }}>
        {content?.linkTextBeforeApplication}
      </Button>
      <Divider sx={{ mb: 3 }} />
      <Box display="flex" alignItems="flex-start" flexDirection="row" gap={2}>
        <Icon iconName={content?.footnoteIcon} />
        <Typography
          dangerouslySetInnerHTML={{
            __html: content.footnoteText,
          }}
        />
      </Box>
    </Box>
  )
}

export default BeforeApplication
