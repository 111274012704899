import React from 'react'
import {
  ListItemText,
  ListItem,
  List,
  Typography,
  ListItemAvatar,
  Avatar,
} from '@mui/material'
import { useEpiContent, useUser } from '@trr/app-shell-data'
import { Content, possibleContentLists } from 'types'
import { replaceTextVariables } from 'utils'

import { Icon } from '../Icon'

const FeatureContentList = ({
  listType,
}: {
  listType: possibleContentLists
}) => {
  const user = useUser()
  const content = useEpiContent<Content>()
  const contentList = content[listType]

  if (contentList?.length === 0) return null

  return (
    <List disablePadding sx={{ pb: 2 }}>
      {contentList?.map((listItem) => (
        <ListItem
          data-testid="ListItem"
          alignItems="flex-start"
          key={listItem.heading}
          divider={false}
          disableGutters
          sx={{ pb: 3 }}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'surface.green' }}>
              <Icon iconName={listItem.icon} color="secondary.main" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              variant: 'h6',
            }}
            primary={listItem.heading}
            secondaryTypographyProps={{
              variant: 'body1',
            }}
            secondary={
              <Typography
                data-testid="ListItemText"
                dangerouslySetInnerHTML={{
                  __html: replaceTextVariables(listItem.content, {
                    companyName: user?.klientProperties?.foretag?.name,
                  }),
                }}
              />
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default FeatureContentList
