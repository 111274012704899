import { useAuthentication } from '@trr/app-shell-data'
import { useGetPendingAnsokanStatusQuery } from 'api'
import { BeforeApplication, AfterApplication } from 'features'
import React from 'react'

const App = () => {
  const { sub } = useAuthentication()
  const { data } = useGetPendingAnsokanStatusQuery(
    { sub },
    { skip: sub === null }
  )
  if (data === undefined) {
    return null
  }
  const hasPendingAnsokan = Boolean(
    data.aktuellAnsokan?.ansokanUnderBearbetning
  )
  return hasPendingAnsokan ? <AfterApplication /> : <BeforeApplication />
}

export default App
